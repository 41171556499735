import styles1 from "../styles/tables";
import styles2 from "../styles/modales";

import React, { useState } from 'react';
import Navbar from "../components/navbar";
import Radium from "radium";
import {useQuery} from "react-query";
import {getMe} from "../api/users";
import {imports} from "../api/imports";
import {useNavigate} from "react-router-dom";

const styles = {...styles1, ...styles2};


function Apps() {
    const navigate = useNavigate();
    const {data: me} = useQuery('me', () => getMe(navigate));

    return (
        <div style={styles.page}>
            <a href={"/"} style={styles.header}>
                <img src={"/assets/images/logo.png"} style={styles.logo} alt="logo" />
            </a>

            <Navbar page={'apps'} me={me} />

            <div style={styles.containerFluid}>
                <div className={'row mb-4'}>

                    <div className={'col-12'}>
                        <div className={'tableBorder'}>
                            <div style={styles.h1}>
                                Applications mobiles
                            </div>

                            <div style={styles.contentBtn}>
                                <div className={'row-table mt-40'}>
                                    <a className="dropdown-item"
                                        href='https://play.google.com/store/apps/details?id=com.kyrnovarentapp'>
                                        <img
                                            alt='Disponible sur Google Play'
                                            style={{width: 150, marginLeft: 10}}
                                            src='https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png'
                                        />
                                    </a>
                                    <a className="dropdown-item"
                                        href='https://apps.apple.com/fr/app/kyrnovarent/id6470309556'>
                                        <img
                                            alt='Disponible sur App Store'
                                            style={{width: 150, marginLeft: 10}}
                                            src='/assets/images/apps/app_store.png'
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Radium(Apps);
