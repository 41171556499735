
const styles = {
    page: {
        backgroundImage: 'url("/assets/images/bg-login.png")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: '#707070',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    box:{
        width: 350,
        height:"auto",
        backgroundColor: "white",
        borderRadius: 7,
    },
    headerBox: {
        borderBottomWidth: 1,
        borderBottomColor: "#4A9FC2",
        borderBottomStyle: "solid",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo:{
        width: 150,
        height: 90,
        objectFit: "contain",
    },
    bodyBox:{
        padding: 20,
    },
    formBox:{
        height:80,
        display: "flex",
        flexDirection: "column",
        cursor: 'pointer'
    },
    label:{
        fontSize: 14,
        fontWeight: 300,
        color: "#4A9FC2",
        marginBottom: 5,
    },
    input:{
        borderColor: "#4A9FC2",
        borderRadius: 7,
        height: 40,
        paddingLeft: 10,
        color: '#707070',
    },
    frameButton:{
        backgroundColor: "#4A9FC2",
        width: '100%',
        height:40,
        borderRadius: 7,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        cursor: 'pointer'
    },
    textButton:{
        color: "white",
        fontSize: 14,
    },
    framePasswordForget:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: -10,
        cursor: 'pointer'
    },
    textPasswordForget:{
        fontSize: 12,
        color: "#4A9FC2",
    },
    frameBackLogin:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: 10,
    }

}

export default styles;
