import React, {useRef} from 'react';
import {Image} from "react-bootstrap";
import styles from "../styles/login";
import {login as doLogin} from "../api/session";
import {useNavigate} from "react-router-dom";
import Radium from "radium";

function Login() {
    const navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();

    const log = async () => {
        const email = emailRef.current?.value;
        const password = passwordRef.current?.value;
        const response = await doLogin(email, password);
        if (response?.response === true) {
            navigate(`/`);
        } else {
            alert(response?.data?.message);
        }
    }

    return (
        <div style={styles.page}>
            <div style={styles.box}>
                <div style={styles.headerBox}>
                    <Image src="../../assets/images/logo.png" style={styles.logo} />
                </div>
                <div style={styles.bodyBox}>
                    <div style={styles.formBox}>
                        <label htmlFor="label" style={styles.label}>Adresse mail</label>
                        <input type="text" style={styles.input} placeholder={'Adresse mail'} ref={emailRef} />
                    </div>
                    <div style={styles.formBox}>
                        <label htmlFor="label" style={styles.label}>Mot de passe</label>
                        <input type="password" style={styles.input} placeholder={'Mot de passe'} ref={passwordRef} />
                    </div>
                    <div style={styles.framePasswordForget} onClick={() => navigate("/password_forgotten")}>
                        <div style={styles.textPasswordForget}>Mot de passe oublié ?</div>
                    </div>
                    <div style={styles.frameButton} onClick={() => log()}>
                        <div style={styles.textButton}>Se connecter</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Radium(Login);
