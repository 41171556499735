
const styles = {

    page:{
        backgroundColor: "white",
        color: "#707070",
    },

    header:{
        padding: '0px 200px',
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: 80,
        '@media (max-width: 900px)': {
            padding: '0px',
        },
    },
    logo:{
        width: 150,
        height: 50,
        objectFit: "contain",
    },
    /* SECTION INFO*/
    SectionInfo:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 200px",
        height: 100,
        backgroundColor: "#fff",
        borderTop: 'solid 1px #70707024',
        '@media (max-width: 900px)': {
            padding: '10px',
        },
    },
    SectionSearch:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "10px 200px",
        height: 100,
        backgroundColor: "#fff",
        borderTop: 'solid 1px #70707024',
        '@media (max-width: 900px)': {
            padding: '10px',
        },
    },
    input:{
        borderColor: "#4A9FC2",
        borderRadius: 7,
        height: 40,
        paddingLeft: 10,
        color: '#707070',
    },
    h1:{
        fontSize: 30,
        fontWeight: 300,
        color: "#707070",
    },
    h1W:{
        fontSize: 30,
        fontWeight: 600,
        color: "#4A9FC2",
    },
    span:{
        fontWeight: 700,
        color: "#4A9FC2",
    },
    spanBlue:{
        fontWeight: 700,
        color: "#4A9FC2",
    },
    icon:{
        width: 18,
        height: 18,
        resizeMode: "contain",
    },
    mr10:{
        marginRight: 10,
    },
    btnRow:{
        border: "2px solid #4A9FC2",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 40,
        padding: "5px 10px",
        borderRadius: 7,
        textDecoration: "none",
        color: "#4A9FC2",
    },
    containerFluid:{
        padding: '0px 200px',
        '@media (max-width: 900px)': {
            padding: '10px',
        },
    },
    contentCard:{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    shadow:{
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        width: 320,
        borderRadius: 7,
        marginRight: 20,
        marginBottom: 20,
    },
    shadow100:{
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        width: '100%',
        borderRadius: 7,
        marginRight: 20,
        marginBottom: 20,
    },
    Card:{
        width: 320,
        borderRadius: 7,
        overflow: "hidden",
    },
    Card100:{
        width: '100%',
        borderRadius: 7,
        overflow: "hidden",
    },
    image_villa:{
        width:" 100%",
        height: "100%",
        objectFit: "cover",
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
    big_image_villa:{
        width:" 100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: 7,
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    },
    Card__title:{
        marginTop: -5,
        backgroundColor: "white",
        padding: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
        overflow: "hidden",
    },
    Card__title__name: {
        fontSize: 16,
        fontWeight: 600,
        color: " #4A9FC2",
        textAlign: "center",
    },
    Card__title__name__double: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: '90%'
    },
    Card__subtitle__name:{
        fontSize: 14,
    },

    btnKRed:{
        border: "2px solid #C24A4A",
        backgroundColor: "#C24A4A",
        color: "white",
        justifyContent: "center",
        alignItems: "center",
        height: 35,
        padding: "3px 10px",
        borderRadius: 7,
        textDecoration: "none",
        marginTop: 10,
    },

    reservation: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },

    imageReservation: {
        width: "80%",
        height: 590,
        marginRight: 20
    },

    infoReservation: {
        width: "35%",
    },

    width100:{
        width: "100%",
    },
    p10: {
        padding: 10,
    },

    backgroundColorGray: {
        backgroundColor: "#F2F2F2",
    },

    years: {
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        width: 50,
        margin: 10,
        padding: 5,
        borderRadius: 7,
        marginRight: 20,
        marginBottom: 20,
        cursor: 'pointer'
    },

    times: {
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        width: 80,
        margin: 10,
        padding: 5,
        borderRadius: 7,
        marginRight: 20,
        marginBottom: 20,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        cursor: 'pointer',
    },

    /* MODAL */
    frameTitlePopup:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: 40,
        //backgroundColor: "red",
    },
    titleH1Popup:{
        fontSize: 16,
        fontWeight: "bold",
        color: "#4A9FC2",
        textTransform: "uppercase",
    },
    borderHr:{
        borderBottom:" 2px solid #4A9FC2",
    },
    contentModal:{
        //backgroundColor:"red",
    },
    contentInput:{
        // display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "yellow",
    },
    label:{
        fontSize: 14,
        color: "#4A9FC2",
    },
    select:{
        width: "100%",
        height: 40,
        borderRadius: 7,
        color: "#707070",
    },
    contentLabel:{
        display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: 20,
        marginBottom: 5,
        // backgroundColor: "blue",
    },
    contentBtn:{
        display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        height: 60,
        marginTop: 20,
        //backgroundColor: "green",
    },
    btnKReverse:{
        border: "2px solid #4A9FC2",
        backgroundColor: "#4A9FC2",
        color: "white",
        justifyContent: "center",
        alignItems: "center",
        height: 40,
        padding: "5px 15px",
        borderRadius: 7,
        display: "flex",
        textDecoration: "none",
    },

    date: {
        width: "100%",
    },

    iconPortail: {
        width: 50,
        height: 50
    },

    iconPortailAirbnb: {
        width: 150,
        height: 50
    },

    iconPortailVrbo: {
        width: 150,
        height: 60
    },

    iconPortailHolidu: {
        width: 150,
        height: 30
    },

    iconPortailHomes: {
        width: 150,
        height: 80
    },

    iconPortailBooking: {
        width: 150,
        height: 40
    },

    iconPortailKyrnova: {
        width: 150,
        height: 60
    },

    divIconPortail: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },

    calendarHide: {
        display: "none",
    },

    calendarShow: {
        display: "block",
    }
}

export const selectStyles = {
    control: (styles) => ({ ...styles, ...{width: "100%", height: 40, borderRadius: 7, color: "#707070"} }),
}

export default styles;
