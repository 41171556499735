
const styles = {



    /*MODAL*/
    /* MODAL */
    frameTitlePopup:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: 40,
        //backgroundColor: "red",
    },
    titleH1Popup:{
        fontSize: 16,
        fontWeight: "bold",
        color: "#4A9FC2",
        textTransform: "uppercase",
    },
    borderHr:{
        borderBottom:" 2px solid #4A9FC2",
    },
    contentModal:{
        //backgroundColor:"red",
    },
    contentInput:{
        display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor: "yellow",
    },
    label:{
        fontSize: 14,
        color: "#4A9FC2",
    },
    select:{
        width: "100%",
        height: 40,
        borderRadius: 7,
        color: "#707070",
        fontSize: 14,
    },
    contentLabel:{
        display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: 20,
        marginBottom: 5,
        // backgroundColor: "blue",
    },
    contentBtn:{
        display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        height: 60,
        marginTop: 20,
        //backgroundColor: "green",
    },
    btnKReverse:{
        border: "2px solid #4A9FC2",
        backgroundColor: "#4A9FC2",
        color: "white",
        justifyContent: "center",
        alignItems: "center",
        height: 40,
        padding: "5px 15px",
        borderRadius: 7,
        display: "flex",
        textDecoration: "none",
    },

    /* MODAL ADD */
    row:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    col:{
        display: "flex",
        flexDirection: "column",
    },
    input:{
        width: "100%",
        height: 40,
        borderRadius: 7,
        border: "1px solid #4A9FC2",
        paddingLeft: 10,
        fontSize: 14,
    },
    mt10:{
        marginTop: 10,
    },
    mt20:{
        marginTop: 20,
    }

//     modalCustom:{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         borderRadius: 7+"!important",
//     },
//     bodyModal:{
//         //backgroundColor: "red",
//         overflow: "scroll",
//         height:"50%",
//         padding: "20px",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//     },
//
//     frameTitlePopup:{
//         position: "relative",
//         display: "flex",
//         flexDirection: "row",
//         justifyContent: "space-between",
//         width: "100%",
//     },
//
//     contentCardModal:{
//         display: "flex",
//         flexDirection: "row",
//         justifyContent: "space-between",
//         alignItems: "center",
//         flexWrap: "wrap",
//         margin: "auto",
//         backgroundColor: "red",
//     },
//     //
//     // modal:{
//     //     display: "flex",
//     //     alignItems: "center",
//     // },
//     //
//     // modalDialog:{
//     //     height:" 60%",
//     // },
//
//     titlePopupH1:{
//         fontSize: 20,
//         fontWeight: "bold",
//         color:" #4A9FC2",
//         marginBottom: 10,
//     },
//
//     mV20:{
//         marginTop:20,
//         paddingBottom: 20,
//     },
//     mt20:{
//         marginTop: 20,
//     },
//     mt10:{
//         marginTop: 10,
//     },
//     mt40:{
//         marginTop: 40,
//     },
//     Card:{
//         width: 300,
//         borderRadius: 7,
//         overflow: "hidden",
//         marginTop:20,
//     },
//     popupImageVilla:{
//         width: "100%",
//         height: 200,
//         resizeMode: "cover",
//         borderRadius: 7,
//     },
//     CardTitle:{
//         color: "#707070",
//         marginBottom: 10,
//         borderBottomLeftRadius: 7,
//         borderBottomRightRadius: 7,
//         boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
//     },
//     CardTitleName:{
//
//     },
//     CardTitleCity:{
//
//     },
//     titleH1Popup:{
//         fontSize: 16,
//         fontWeight: "bold",
//         color: "#4A9FC2",
//         marginBottom: 10,
//         textTransform: "uppercase",
//     },
//     borderHr:{
//         borderBottom:" 2px solid #4A9FC2",
//     },
//
//     /* input */
//     blocInput:{
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "flex-start",
//         marginBottom: 20,
//         padding:20,
//     },
//     label:{
//         fontSize: 16,
//         fontWeight: 300,
//         color: "#4A9FC2",
//         marginBottom: 10,
//     },
// // input, select, input:focus :{
// //     width: 100%,
// //         height: 40px,
// //     border-width: 1px!important,
// //     border-style: solid!important,
// //     border-color: #4A9FC2!important,
// //     border-radius: 7px,
// //         display: flex,
// //     justify-content: center,
// //     align-items: center,
// //         padding:0 10px,
// // },
// //
// // select, ::placeholder, input:{
// //     color: #4A9FC2!important,
// // // },
// //
// // ::placeholder, select:{
// //     font-style: italic,
// // },
//
//
//
//     /*MODAL LITTLE*/
//
//     modalLittleCustom:{
//         width: "40%",
//         height: "auto",
//         margin: "auto",
//         display: "flex",
//         borderRadius: 7,
//     },
//
//     modalDelCustom :{
//         width: "30%",
//         height: "auto",
//         margin: "auto",
//         display: "flex",
//         borderRadius: 7,
//     },
//
//     rowModal:{
//         display: "flex",
//         flexDirection: "row",
//         justifyContent:" space-around",
//         alignItems: "center",
//         marginTop:20,
//     },
//
//     columnModal:{
//         display: "flex",
//         flexDirection: "column",
//         justifyContent:" flex-start",
//         alignItems: "flex-start",
//         marginTop:20,
//     },

}

export default styles;
