export function dateEnToFr(date) {
    const dateArray = date.split('-');
    return dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
}

export function dateEnToFr2(date) {
    const dateArray = date.split('-');
    return dateArray[2] + '/' + dateArray[1];
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
