import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import styles from "../styles/index";
import Navbar from "../components/navbar";
import Radium from 'radium';
import {useQuery} from "react-query";
import {getProperties} from "../api/properties";
import {getMe} from "../api/users";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Header from "../components/header";

function Index() {
    const navigate = useNavigate();
    const {data: properties, isLoading} = useQuery('properties', () => getProperties());
    const {data: me} = useQuery('me', () => getMe(navigate));
    const [accordeon, setAccordeon] = useState(true);

    const changeAccordeon = (id, value) => {
        let a ;
        if (typeof accordeon === 'object')
            a = accordeon;
        else {
            a = [];
            properties?.map(property => {
                a[property?.id] = accordeon;
            });
        }

        alert(value);
        a[id] = value;
        setAccordeon(a);
    }

    const changeAccordeons = (value) => {
        let accordeon = [];
        properties?.map(property => {
            accordeon[property?.id] = value;
        });
        setAccordeon(accordeon);
    }

    const showKind = (kind) => {
        switch (kind) {
            case 'Apartment':
                return 'Appartement';
            default:
                return kind;
        }
    }

    return (
        <div style={styles.page}>

            <Header page="dashboard" />
            <Navbar page="dashboard" me={me}/>
            <div style={styles.SectionInfo}>
                <div style={styles.h1}>
                    Bienvenue <span style={styles.h1W}>{me?.firstname}</span>
                </div>
                <div>
                    Sur votre Dashboard <span style={styles.spanBlue}>KYRNOVA Rent</span>
                </div>
            </div>

            <div style={styles.SectionCards}>

                <div style={styles.titleWhite}>LOGEMENTS SOUS GESTION</div>

                {!isLoading ?
                    (<OwlCarousel style={styles.owlCarousel} margin={10} autoWidth={true}>
                        {properties?.sort((a, b) => a.name.localeCompare(b.name)).map(property => (
                            <a href={"/logements/" + property?.id + "/reservations"} style={styles.shadow}>
                                <div style={styles.Card}>
                                    <img src={property?.photo_url ?? "/assets/images/villa.png"} alt="image_villa"
                                         style={styles.image_villa}/>
                                    <div style={styles.CardTitle}>
                                    <div style={styles.CardTitleName}>
                                        {property?.name}
                                    </div>
                                    <div style={styles.CardSubtitleName}>
                                        {showKind(property?.kind)}
                                    </div>
                                </div>
                            </div>
                        </a>))}
                    </OwlCarousel>)
                    :
                    (<p>Chargement en cours</p>)
                }
            </div>
            <div style={styles.containerFluid} className={'container-fluid'}>
                <div className={'row mb-4'}>
                    <div style={styles.white}>
                        <span onClick={() => changeAccordeons(false)} style={styles.span}>Tout réduire</span> | <span onClick={() => changeAccordeons(true)} style={styles.span}>Tout développer</span>
                    </div>
                </div>
                {!isLoading && properties?.sort((a, b) => a.name.localeCompare(b.name)).map(property => (<div className={'row mb-4'} key={property?.id}>
                    <div className={'col-12'}>
                        <section className="card card-table" style={styles.calendar}>
                            <div className="row p-3">
                                <div className="col-11">
                                    <h5 style={styles.h5}>{property?.name}</h5>
                                </div>
                                <div className="col-1">
                                    <div>{(accordeon === true || accordeon[property?.id]) ? (<span onClick={() => changeAccordeon(property?.id, false)}><FontAwesomeIcon icon={faChevronUp} color={"#4d9bbc"} /></span>) : (<span onClick={() => changeAccordeon(property?.id, true)}><FontAwesomeIcon icon={faChevronDown} color={"#4d9bbc"}/></span>)}</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div id="calendar" className={`calendars fc fc-unthemed fc-ltr ${(accordeon === true || accordeon[property?.id]) ? '' : 'calendarHidden'}`} data-logement-id={property?.id}>
                                            <div>Chargement en cours...</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>))}
            </div>



        </div>
    );
}

export default Radium(Index);
