import React, {useRef} from 'react';
import {Image} from "react-bootstrap";
import styles from "../styles/login";
import {useNavigate} from "react-router-dom";
import Radium from "radium";
import {doForgetPassword} from "../api/session";

function PasswordForget() {
    const navigate = useNavigate();
    const emailRef = useRef();

    const log = async () => {
        const email = emailRef.current?.value;
        const response = await doForgetPassword(email);
        if (response?.response === true) {
            navigate(`/login`);
        } else {
            alert(response?.data?.message);
        }
    }

    return (
        <div style={styles.page}>
            <div style={styles.box}>
                <div style={styles.headerBox}>
                    <Image src="../../assets/images/logo.png" style={styles.logo} />
                </div>
                <div style={styles.bodyBox}>
                    <div style={styles.formBox}>
                        <label htmlFor="label" style={styles.label}>Adresse mail</label>
                        <input type="text" style={styles.input} placeholder={'Adresse mail'} ref={emailRef} />
                    </div>

                    <div style={styles.frameButton} onClick={() => log()}>
                        <div style={styles.textButton}>Réinitialiser le mot de passe</div>
                    </div>

                    <div style={styles.frameBackLogin} onClick={() => navigate("/login")}>
                        <div style={styles.textPasswordForget}>Revenir sur la page de connexion</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Radium(PasswordForget);
