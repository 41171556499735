import axiosInstance from "../interceptor/tokenInterceptor";
import {rootApi} from "./config";
import qs from "qs";
import {logout} from "./session";

export const getUsers = async () => {
    const response = await axiosInstance.get(rootApi + '/users')
        .catch(err => {
            console.log("erreur " +JSON.stringify(err));
        });
    return response.data;
};
export const getMe = async (navigate) => {
    const response = await axiosInstance.get(rootApi + '/users/me')
        .catch(err => {
            console.log("erreur " +JSON.stringify(err));
            logout();
            navigate(`/login`);
        });
    return response.data;
};

export const getConcierges = async () => {
    const response = await axiosInstance.get(rootApi + '/users?role=concierge');
    return response.data;
};

export const getProprietaires = async () => {
    const response = await axiosInstance.get(rootApi + '/users?role=proprietaire');
    return response.data;
};

export const createUser = async user => {
    const response = await axiosInstance
        .post(rootApi + '/users', qs.stringify(user))
        .catch(err => {
            console.log(JSON.stringify(err.response.data));
            return {response: false, data: err.response.data};
        });

    return {response: true, data: response.data};
};

export const updateUser = async (id, user) => {
    const response = await axiosInstance
        .patch(rootApi + '/users/' + id, qs.stringify(user))
        .catch(err => {
            console.log(JSON.stringify(err.response.data));
            return {response: false, data: err.response.data};
        });

    if (response.data) {
        // eneregistrer
        return {response: true, data: response.data};
    } else {
        return {response: false};
    }
};

export const deleteUser = async id => {
    let response = await axiosInstance
        .delete(rootApi + '/users/' + id)
        .catch(e => {
            //console.log(e);
            console.log(JSON.stringify(e.response.data));
            return {response: false, data: e.response.data};
        });
    return response.data;
};
