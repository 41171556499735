
import Modal from 'react-bootstrap/Modal';
import styles1 from "../styles/tables";
import styles2 from "../styles/modales";

import React, { useState } from 'react';
import Navbar from "../components/navbar";
import Radium from "radium";
import {useQuery, useQueryClient} from "react-query";
import {createUser, deleteUser as deleteUserApi, getConcierges, getMe, updateUser} from "../api/users";
import {useNavigate} from "react-router-dom";
import Header from "../components/header";

const styles = {...styles1, ...styles2};


function Concierges() {
    const navigate = useNavigate();
    const {data: me} = useQuery('me', () => getMe(navigate));
    const queryClient = useQueryClient();
    const {data: concierges} = useQuery('concierges', getConcierges);
    const [selectedConcierge, setSelectedConcierge] = useState(null);
    const [addConcierge, setAddConcierge] = useState(false);
    const [editConcierge, setEditConcierge] = useState(false);
    const [deleteConcierge, setDeleteConcierge] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');

    const doAddConcierge = async () => {
        let user = {firstname, lastname, email, role: 'concierge'};
        if (password !== '' && password_confirmation !== '') {
            user.password = password ;
            user.password_confirmation = password_confirmation ;
        }
        let response = await createUser(user);
        if (response?.data?.id) {
            console.log(response);
            setFirstname(null);
            setLastname(null);
            setEmail(null);
            setAddConcierge(false);
            queryClient.invalidateQueries(['concierges'])
        } else {
            alert('Veuillez vérifier les champs ! ');
        }
    }

    const doEditConcierge = async () => {
        let user = {firstname, lastname, email}
        if (password !== '' && password_confirmation !== '') {
            user.password = password ;
            user.password_confirmation = password_confirmation ;
        }
        const response = await updateUser(selectedConcierge.id, user);
        if(response?.data?.id) {
            setFirstname(null);
            setLastname(null);
            setEmail(null);
            setSelectedConcierge(null);
            setEditConcierge(false);
            queryClient.invalidateQueries(['concierges'])
        } else {
            alert('Veuillez vérifier les champs ! ');
        }
    }

    const doDeleteConcierge = async () => {
        await deleteUserApi(selectedConcierge.id);
        setSelectedConcierge(null);
        setDeleteConcierge(false);
        queryClient.invalidateQueries(['concierges'])
    }

    const Form = () => (
        <div className={"row"} style={styles.mt20}>
            <div className={"col-6"}>
                <label htmlFor="label" style={styles.label}>Nom</label>
                <input type="text" id="label" placeholder={"Nom utilisateur"} style={styles.input} value={lastname} onChange={(e) => setLastname(e.target.value)} />
            </div>
            <div className={"col-6"} >
                <label htmlFor="label" style={styles.label}>Prénom</label>
                <input type="text" id="label" placeholder={"Prénom utilisateur"} style={styles.input} value={firstname} onChange={(e) => setFirstname(e.target.value)} />
            </div>
            <div className={"col-12"} style={styles.mt20}>
                <label htmlFor="label" style={styles.label}>Adresse mail</label>
                <input type="text" id="label" placeholder={"Adresse mail"} style={styles.input} value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className={"col-12"} style={styles.mt20}>
                <label htmlFor="label" style={styles.label}>Mot de passe</label>
                <input type="text" id="label" placeholder={"Mot de passe"} style={styles.input} value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className={"col-12"} style={styles.mt20}>
                <label htmlFor="label" style={styles.label}>Confirmation du mot de passe</label>
                <input type="text" id="label" placeholder={"Confirmation du mot de passe"} style={styles.input} value={password_confirmation} onChange={(e) => setPassword_confirmation(e.target.value)} />
            </div>
        </div>
    )

    return (
        <div style={styles.page}>
            <Header />
            <Navbar page={'concierges'} me={me}  />
            <div style={styles.SectionInfo}>
                <div style={styles.h1}>
                    Liste des <span style={styles.h1W}>concierges</span>
                </div>
                <a href={'#'} style={styles.btnRow} onClick={() => setAddConcierge(true)}>
                    <img src={"/assets/icons/add-user.png"} style={styles.iconAdd} alt="add-user" />
                    Ajouter un concierge
                </a>
            </div>

            <div style={styles.containerFluid}>
                <div className={'row mb-4'}>

                    <div className={'col-12'}>
                        <div className={'tableBorder'}>

                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th style={styles.headerTable} scope="col"></th>
                                    <th style={styles.headerTable} scope="col">Nom</th>
                                    <th style={styles.headerTable} scope="col">Prénom</th>
                                    <th style={styles.headerTable} scope="col">Location sous gestion</th>
                                    <th style={styles.headerTable} scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {concierges?.map(concierge => (<tr style={styles.trTable} key={concierge.id}>
                                        <td><img src={"/assets/icons/user.png"} style={styles.icon} alt="user" /></td>
                                        <td style={styles.textWeight}>{concierge?.firstname}</td>
                                        <td style={styles.textWeight}>{concierge?.lastname}</td>
                                        <td>
                                            <div style={styles.rowTable}>
                                                <a style={{...styles.btnK, ...styles.mr10}} href={'/users/'+concierge.id+'/logements'}>{concierge?.properties_length} location{concierge?.properties_length > 1 && 's'}</a>
                                                {/*<a style={styles.btnKReverse} href={'#'} onClick={() => setAddConcierge(true)}><img src={"/assets/icons/add-home.png"} style={styles.icon} alt="add-home" /></a>*/}
                                            </div>
                                        </td>
                                        <td>
                                            <div style={styles.rowTable}>
                                                <a style={{...styles.btnKReverse, ...styles.mr10}} href={'#'} onClick={() => { setFirstname(concierge?.firstname); setLastname(concierge?.lastname); setEmail(concierge?.email) ; setSelectedConcierge(concierge); setEditConcierge(true)}}>Modifier</a>
                                                <a style={styles.btnKRed} href={'#'} onClick={() => { setSelectedConcierge(concierge); setDeleteConcierge(true)}}>Supprimer</a>
                                            </div>
                                        </td>
                                    </tr>))}

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            <Modal
                show={addConcierge}
                onHide={() => setAddConcierge(false)}
                contentClassName="modal-little-custom"
                fullscreen={false}
            >
                <Modal.Body>
                    <div style={{...styles.borderHr, ...styles.frameTitlePopup}}>
                        <div style={{...styles.icon, ...styles.mr10}} />
                        <div style={styles.titleH1Popup}>
                            Ajouter un concierge
                        </div>
                        <a href={'/concierges'}>
                            <img src={"/assets/icons/close.png"} alt="icon-close" style={{...styles.icon, ...styles.mr10}} />
                        </a>
                    </div>
                    <div style={styles.contentModal}>
                        {Form()}
                        <div style={styles.contentBtn}>
                            <div className={'row-table mt-40'}>
                                <a style={styles.btnKReverse} href={'#'} onClick={() => doAddConcierge()}><img src={"/assets/icons/add-home.png"} style={{...styles.icon, ...styles.mr10}} alt="add-home" /> Ajouter un concierge</a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={editConcierge}
                onHide={() => setEditConcierge(false)}
                contentClassName="modal-little-custom"
                fullscreen={false}
            >
                <Modal.Body>
                    <div style={{...styles.borderHr, ...styles.frameTitlePopup}}>
                        <div style={{...styles.icon, ...styles.mr10}} />
                        <div style={styles.titleH1Popup}>
                            Modifier un concierge
                        </div>
                        <a href={'/concierges'}>
                            <img src={"/assets/icons/close.png"} alt="icon-close" style={{...styles.icon, ...styles.mr10}} />
                        </a>
                    </div>
                    <div style={styles.contentModal}>
                        {Form()}
                        <div style={styles.contentBtn}>
                            <div className={'row-table mt-40'}>
                                <a style={styles.btnKReverse} href={'#'} onClick={() => doEditConcierge()}><img src={"/assets/icons/add-home.png"} style={{...styles.icon, ...styles.mr10}} alt="add-home" /> Enregistrer</a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={deleteConcierge}
                onHide={() => setDeleteConcierge(false)}
                contentClassName="modal-little-custom"
                fullscreen={false}
            >
                <Modal.Body>
                    <div style={{...styles.borderHr, ...styles.frameTitlePopup}}>
                        <div style={{...styles.icon, ...styles.mr10}} />
                        <div style={styles.titleH1Popup}>
                            Supprimer le concierge
                        </div>
                        <a href={'/concierges'}>
                            <img src={"/assets/icons/close.png"} alt="icon-close" style={{...styles.icon, ...styles.mr10}} />
                        </a>
                    </div>
                    <div style={styles.contentModal}>
                        <div style={styles.contentLabel}>
                            <label htmlFor="label" style={styles.label}>Supprimer le concierge</label>
                        </div>
                        <div style={styles.contentBtn}>
                            <div className={'row-table mt-40'}>
                                <a style={styles.btnKReverse} href={'#'} onClick={() => doDeleteConcierge()}> Supprimer</a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


        </div>
    );
}

export default Radium(Concierges);
