import Radium from "radium";
import React from "react";
import styles from "../styles/index";
import styles1 from "../styles/tables";

function Header({page = ''}) {

    return (

        <div className={'row'}>
            {page === 'dashboard' && <div className="col-2"/>}
            <div className={page === 'dashboard' ? "col-8" : "col-10"}>
                <a href={"/"} style={[page === 'dashboard' ? styles.header : styles1.header]}>
                    <img src={"/assets/images/logo.png"} style={[page === 'dashboard' ? styles.logo : styles1.logo]} alt="logo"/>
                </a>
            </div>
            <div className={page === 'dashboard' ? "col-2" : "col-1"}>
                <a className="dropdown-item"
                   href='https://play.google.com/store/apps/details?id=com.kyrnovarentapp'>
                    <img
                        alt='Disponible sur Google Play'
                        style={{width: 100, marginLeft: 1}}
                        src='https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png'
                    />
                </a>
                <a className="dropdown-item"
                   href='https://apps.apple.com/fr/app/kyrnovarent/id6470309556'>
                    <img
                        alt='Disponible sur App Store'
                        style={{width: 100, marginLeft: 1}}
                        src='/assets/images/apps/app_store.png'
                    />
                </a>
            </div>
        </div>
    );
}

export default Radium(Header);
