import routes, {getRoutes} from "./routes";
import {Route, Routes, Navigate, useNavigate} from "react-router-dom";
import Radium, { StyleRoot } from 'radium';
import {QueryClient, QueryClientProvider} from "react-query";
import {useEffect} from "react";
import session from "./utils/session";

function App() {
  const queryClient = new QueryClient();
    const navigate = useNavigate();

  useEffect(() => {
      checkLoged() ;
  }, []);
    const checkLoged = async () => {
        const token = await session.getToken();
        if(!token) {
            navigate(`/login`);
        }
    }
  return (
      <QueryClientProvider client={queryClient}>
      <StyleRoot>
          <div>
              <Routes>
                {getRoutes(routes())}
                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
          </div>
      </StyleRoot>
      </QueryClientProvider>
  );
}

export default Radium(App);
