const styles = {

    page:{
        backgroundColor: "white",
        color: "#707070",
    },
    header:{
        padding: '0px 200px',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '@media (max-width: 900px)': {
            padding: '0px',
        },
    },
    logo: {
        width: 200,
        height: 100,
        objectFit: "contain",
    },
    p200:{
        padding: '0px 200px',
        '@media (max-width: 900px)': {
            padding: '0px',
        },
    },
    /* SECTION INFO*/
    SectionInfo:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        padding: "10px 200px",
        height: 100,
        backgroundColor: "#fff",
        borderTop: 'solid 1px #70707024',
        '@media (max-width: 900px)': {
          padding: '10px',
        },
    },
    h1:{
        fontSize: 30,
        fontWeight: 300,
        color: "#707070",
    },
    h1W:{
        fontSize: 30,
        fontWeight: 600,
        color: "#4A9FC2",
    },
    span:{
        fontWeight: 700,
        color: "white",
        cursor: "pointer",
    },
    spanBlue:{
        fontWeight: 700,
        color: "#4A9FC2",
    },

    /*SECTION CARDS */
    containerFluid:{
        padding: "0px 200px",
        marginTop: "-250px",
        '@media (max-width: 900px)': {
            padding: '10px',
            marginTop: "-40px",
        },
    },
    SectionCards:{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        paddingTop: 20,
        padding: "20px 0px 200px 200px",
        backgroundColor: "#4A9FC2",
        '@media (max-width: 900px)': {
            padding: '10px',
        },
    },
    titleWhite:{
        fontSize: 20,
        color: "white",
        fontWeight: 800,
        marginBottom: 15,
    },
    owlCarousel:{
        marginBottom:40,
    },
    shadow:{
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        width: 240,
        borderRadius: 7,
        marginRight: 20,
        marginBottom: 20,
    },
    Card:{
        width: 400,
        borderRadius: 7,
        overflow: "hidden",
    },
    image_villa:{
        width:" 100%",
        height: 250,
        objectFit: "cover",
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
    CardTitle:{
        backgroundColor: "white",
        padding: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    CardTitleName:{
        fontSize: 16,
        fontWeight: 600,
        color:" #4A9FC2",
    },
    CardSubtitleName:{
        fontSize: 14,
        color: "#707070",
    },
    calendar:{
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",

    },
    h5:{
      color: "#4A9FC2",
    },
    white: {
        color: "white",
        fontWeight: 800,
    },
}


export default styles;

