export const asyncLocalStorage = {
  setItem: async function (key, value) {
    await null;
    return localStorage.setItem(key, value);
  },
  getItem: async function (key) {
    await null;
    return localStorage.getItem(key);
  },
  removeItem: async function (key) {
    await null; // why ?
    return localStorage.removeItem(key);
  }
};