
const styles = {

    page:{
        backgroundColor: "white",
        color: "#707070",
    },

    header:{
        padding: '0px 200px',
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: 80,
        '@media (max-width: 900px)': {
            padding: '0px',
        },
    },
    logo:{
        width: 150,
        height: 50,
        objectFit: "contain",
    },
    /* SECTION INFO*/
    SectionInfo:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 200px",
        height: 100,
        backgroundColor: "#fff",
        borderTop: 'solid 1px #70707024',
        '@media (max-width: 900px)': {
            padding: '10px',
        },
    },
    headerTable:{
      textTransform: "uppercase",
        color: "#4A9FC2",
    },
    h1:{
        fontSize: 30,
        fontWeight: 300,
        color: "#707070",
    },
    h1W:{
        fontSize: 30,
        fontWeight: 600,
        color: "#4A9FC2",
    },
    span:{
        fontWeight: 700,
        color: "#4A9FC2",
    },
    spanBlue:{
        fontWeight: 700,
        color: "#4A9FC2",
    },

    /* TABLE */
    containerFluid:{
        padding: '0px 200px',
        '@media (max-width: 900px)': {
            padding: '10px',
        },
    },

    trTable:{
        verticalAlign: "middle",
    },
    rowTable:{
        display: "flex",
        flexDirection: "row",
    },
    btnRow:{
        border: "2px solid #4A9FC2",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 40,
        padding: "5px 10px",
        borderRadius: 7,
        textDecoration: "none",
        color: "#4A9FC2",
    },
    icon:{
        width: 20,
        height: 20,
        resizeMode: "contain",
    },
    iconAdd:{
        width: 20,
        height: 20,
        marginRight: 10,
        resizeMode: "contain",
    },
    mr10:{
      marginRight: 10,
    },
    textWeight:{
        fontWeight: 600,
        color: "#707070",
    },
    btnK:{
        border: "2px solid #4A9FC2",
        justifyContent: "center",
        alignItems: "center",
        height: 40,
        padding:" 5px 15px",
        borderRadius: 7,
        fontWeight: "bold",
        textDecoration: "none",
        color:"#4A9FC2",
    },
    btnKReverse:{
        border: "2px solid #4A9FC2",
        backgroundColor: "#4A9FC2",
        color: "white",
        justifyContent: "center",
        alignItems: "center",
        height: 40,
        padding: "5px 15px",
        borderRadius: 7,
        display: "flex",
        textDecoration: "none",
    },
    btnKRed:{
        border: "2px solid #C24A4A",
        backgroundColor: "#C24A4A",
        color: "white",
        justifyContent: "center",
        alignItems: "center",
        height: 40,
        padding: "5px 10px",
        borderRadius: 7,
        textDecoration: "none",
    },
}

export default styles;
