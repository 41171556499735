import styles from "../styles/locations";
import React from 'react';
import Navbar from "../components/navbar";
import Radium from "radium";
import {useQuery} from "react-query";
import {dateEnToFr} from "../utils/utils";
import {getMe} from "../api/users";
import {useNavigate, useParams} from "react-router-dom";
import {getReservation} from "../api/reservations";
import Header from "../components/header";
import {getPhotosProperty} from "../api/properties";

function Reservation() {
    const navigate = useNavigate();
    const { id } = useParams();
    const {data: reservation} = useQuery('reservation', () => getReservation(id));
    const {data: photos} = useQuery('photos', () => getPhotosProperty(reservation.property_object.id), {
        enabled: !!reservation?.property_object?.id
    });
    const {data: me} = useQuery('me', () => getMe(navigate));

    const iconPortail = (portail) => {
        switch (portail) {
            case 'www.airbnb.com':
                return <img src={"/assets/icons/portails/airbnb.png"} style={styles.iconPortailAirbnb} alt="Airbnb" title={"Airbnb"}/>
            case 'www.booking.com':
                return <img src={"/assets/icons/portails/booking.png"} style={styles.iconPortailBooking} alt="Booking" title={"Booking"} />
            case 'www.kyrnovarent.com':
                return <img src={"/assets/icons/portails/kyrnovarent.png"} style={styles.iconPortailKyrnova} alt="KyrnovaRent" title={"KyrnovaRent"} />
            case 'www.vrbo.com':
                return <img src={"/assets/icons/portails/vrbo.png"} style={styles.iconPortailVrbo} alt="Vrbo" title={"Vrbo"} />
            case 'www.holidu.com':
                return <img src={"/assets/icons/portails/holidu.png"} style={styles.iconPortailHolidu} alt="Vrbo" title={"Holidu"} />
            case 'Homes & Villas by Marriott International':
                return <img src={"/assets/icons/portails/homes-and-villas.png"} style={styles.iconPortailHomes} alt="Vrbo" title={"Home to go"} />
            case 'www.hometogo.de':
                return <img src={"/assets/icons/portails/home-to-go.png"} style={styles.iconPortailHomes} alt="Vrbo" title={"Home to go"} />
            default:
                return <img src={"/assets/icons/portails/kyrnovarent.png"} style={styles.iconPortailKyrnova} alt="Autre" title={"Autre"}/>
        }
    }

    return (
        <div style={styles.page}>
            <Header />
            <Navbar page={'reservations'} me={me} />
            <div style={styles.SectionInfo}>
                <div style={styles.h1}>
                    <span style={styles.h1W}>Réservation {reservation?.id} {reservation?.property_object?.name}</span>
                </div>
            </div>

            <div style={styles.containerFluid}>

                <div style={styles.contentCard}>
                    {!reservation && <div>Chargement en cours</div>}

                    {reservation && <>
                        <div style={styles.reservation}>
                            <div style={styles.imageReservation}>
                                <img src={photos?.photofull ?? "/assets/images/villa.png"} alt="image_villa" style={styles.big_image_villa} />
                            </div>
                            <div style={styles.infoReservation}>
                                <div style={styles.shadow}>
                                    <div style={styles.Card}>
                                        <div style={styles.Card__title}>
                                            <div style={styles.Card__title__name}>
                                                Réservation
                                            </div>
                                            <div style={{...styles.Card__subtitle__name, ...styles.width100, ...styles.p10}}>
                                                <p>{reservation?.property_object?.name}</p>
                                                <div style={styles.divIconPortail}>
                                                    {iconPortail(reservation?.portail)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={styles.shadow}>
                                    <div style={styles.Card}>
                                        <div style={styles.Card__title}>
                                            <div style={styles.Card__title__name}>
                                                Informations de réservation
                                            </div>
                                            <div style={{...styles.Card__subtitle__name, ...styles.width100, ...styles.p10}}>
                                                <p>Entrée {dateEnToFr(reservation?.arrival_date)} à {reservation?.checkin_schedule}</p>
                                                <p>Sortie {dateEnToFr(reservation?.departure_date)} à {reservation?.checkout_schedule}</p>
                                                <p>Nuits : {reservation?.nights}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={styles.shadow}>
                                    <div style={styles.Card}>
                                        <div style={styles.Card__title}>
                                            <div style={styles.Card__title__name}>
                                                Contact
                                            </div>
                                            <div style={{...styles.Card__subtitle__name, ...styles.width100, ...styles.p10}}>
                                                <p>Nom : {reservation?.client_name}</p>
                                                <p>Téléphone : {reservation?.client_phone}</p>
                                                <p>Email : {reservation?.client_email}</p>
                                                <p>Adultes : {reservation?.adults}</p>
                                                <p>Enfants : {reservation?.childs} {`${reservation?.childs > 0 ? "("+reservation?.childs_age+")" : ""}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={styles.shadow}>
                                    <div style={styles.Card}>
                                        <div style={styles.Card__title}>
                                            <div style={styles.Card__title__name}>
                                                Moyen de paiement
                                            </div>
                                            <div style={styles.Card__subtitle__name}>
                                                <p>{reservation?.payment_method === '43' ? 'Carte de crédit' : 'Virement bancaire'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>

            </div>
        </div>
    );
}

export default Radium(Reservation);
