import axios from 'axios';
import {rootApi} from './config';
import qs from 'qs';
import session from '../utils/session';

export const login = async (email, password = '') => {
    let response = await axios
        .post(
            rootApi + '/tokens',
            qs.stringify({
                email: email,
                password: password,
            }),
        )
        .catch(e => {
            //console.log(e);
            console.log("erreur", JSON.stringify(e));
            return e.response;
        });

    // alert(JSON.stringify(response));
    if (response?.status === 200) {
        await session.setToken(response?.data?.token);
        // await session.setEmail(email);
        // await session.setPassword(password);
        return {response: true, data: response?.data} ;
    } else {
        return {response: false, data: response?.data};
    }
};

export const doForgetPassword = async (email) => {
    let response = await axios
        .post(
            rootApi + '/forget-password',
            qs.stringify({
                email: email,
            }),
        )
        .catch(e => {
            //console.log(e);
            console.log("erreur", JSON.stringify(e));
            return e.response;
        });

    if (response?.status === 200) {
        return {response: true, data: response?.data} ;
    } else {
        return {response: false, data: response?.data};
    }
};

export const logout = async () => {
    await session.setToken(null);
    await session.setEmail(null);
    await session.setPassword(null);
    return true;
};
