import styles, {selectStyles} from "../styles/locations";
import Modal from 'react-bootstrap/Modal';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Navbar from "../components/navbar";
import Radium from "radium";
import {useQuery, useQueryClient} from "react-query";
import {addUserProperty, deleteUserProperty, getProperties, updateProperty} from "../api/properties";
import {useNavigate, useParams} from "react-router-dom";
import {getMe} from "../api/users";
import Select, {StylesConfig} from 'react-select'
import makeAnimated from 'react-select/animated';
import Header from "../components/header";


function Properties() {
    const navigate = useNavigate();
    const { user_id } = useParams();
    const queryClient = useQueryClient();
    const {data: properties} = useQuery('properties', () => getProperties(user_id));
    const {data: all_properties} = useQuery('all_properties', () => getProperties());
    const {data: me} = useQuery('me', () => getMe(navigate));
    const [deleteLocation, setDeleteLocations] = useState(false);
    const [assignLocation, setAssignLocation] = useState(false);
    const [photoLocation, setPhotoLocation] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [property, setProperty] = useState(null);
    const animatedComponents = makeAnimated();
    const [search, setSearch] = useState('');


    const removeProperty = async () => {
        await deleteUserProperty(user_id, property.id);
        setDeleteLocations(false);
        setProperty(null);
        queryClient.invalidateQueries(['properties'])
    }

    const addProperty = async () => {
        for(const p of property) {
            await addUserProperty(user_id, p.value);
        }
        setAssignLocation(false);
        setProperty(null);
        queryClient.invalidateQueries(['properties'])
    }

     const onFileChange = (e) => {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);

        fileReader.onload = (event) => {
            setSelectedImage(event.target.result);
        }
    }

    const photoProperty = async () => {
        const response = await updateProperty(property.id, {photo: selectedImage})
        if(response?.data?.id) {
            setPhotoLocation(false);
            setSelectedImage(null)
            setProperty(null);
            queryClient.invalidateQueries(['properties'])
        } else {
            alert('Veuillez vérifier les champs ! ');
        }
    }

    return (
        <div style={styles.page}>
            <Header/>
            <Navbar page={'logements'} me={me}/>
            <div style={styles.SectionInfo}>
                <div style={styles.h1}>
                    Liste {user_id ? 'de ses' : 'des'} <span style={styles.h1W}>logements</span>
                </div>
                {user_id && (<a href={'#'} style={styles.btnRow} onClick={() => setAssignLocation(true)}>
                    <img src={"/assets/icons/add-user.png"} style={{...styles.icon, ...styles.mr10}} alt="add-user"/>
                    Attribuer un logement
                </a>)}
            </div>

            <div style={styles.SectionSearch}>
                <label htmlFor="label" style={styles.label}>Rechercher...</label>
                <input type="text" style={styles.input} placeholder={'Rechercher...'} value={search}
                       onChange={(e) => setSearch(e.target.value)}/>
            </div>

            <div style={styles.containerFluid}>
                <div style={styles.contentCard}>
                    {properties?.filter(property => search === null || property.name.toLowerCase().indexOf(search.toLowerCase()) > -1).map(property => (<div style={styles.shadow} key={property.id}>
                        <div style={styles.Card}>
                            <a onClick={() => {
                                if (me?.role === 'admin') {
                                    setProperty(property);
                                    setPhotoLocation(true);
                                }
                            }}>
                                <img src={property?.photo_url ?? "/assets/images/villa.png"} alt="image_villa"
                                     style={styles.image_villa}/>
                            </a>
                            <a href={`/logements/${property.id}/reservations`}>
                                <div style={styles.Card__title}>
                                    <div style={styles.Card__title__name}>
                                        {property.name}
                                    </div>
                                    <div style={styles.Card__subtitle__name}>
                                        {property.kind}
                                    </div>
                                    {user_id && (<a href={"#"} style={styles.btnKRed} onClick={() => {
                                        setProperty(property);
                                        setDeleteLocations(true)
                                    }}>Supprimer</a>)}
                                </div>
                            </a>
                        </div>
                    </div>))}
                </div>
            </div>

            <Modal
                show={assignLocation}
                onHide={() => setAssignLocation(false)}
                contentClassName="modal-little-custom"
                fullscreen={false}
            >
                <Modal.Body>
                    <div style={{...styles.borderHr, ...styles.frameTitlePopup}}>
                        <div style={{...styles.icon, ...styles.mr10}}/>
                        <div style={styles.titleH1Popup}>
                            Attribuer une location
                        </div>
                        <a href={'#'} onClick={() => setAssignLocation(false)}>
                            <img src={"/assets/icons/close.png"} alt="icon-close"
                                 style={{...styles.icon, ...styles.mr10}}/>
                        </a>
                    </div>
                    <div style={styles.contentModal}>
                        <div style={styles.contentLabel}>
                            <label htmlFor="label" style={styles.label}>Attribuer une location</label>
                        </div>
                        <div style={styles.contentInput}>
                            <Select
                                options={all_properties?.map(property => ({value: property.id, label: property.name}))}
                                onChange={(e) => setProperty(e)}
                                isMulti
                                components={animatedComponents}
                                placeholder={'Sélectionner une location'}
                            />
                        </div>
                        <div style={styles.contentBtn}>
                            <div className={'row-table mt-40'}>
                                <a style={styles.btnKReverse} href={'#'} onClick={() => addProperty()}><img
                                    src={"/assets/icons/add-home.png"} style={{...styles.icon, ...styles.mr10}}
                                    alt="add-home"/> Attribuer</a>
                            </div>
                        </div>


                    </div>

                </Modal.Body>
            </Modal>

            <Modal
                show={deleteLocation}
                onHide={() => setDeleteLocations(false)}
                contentClassName="modal-little-custom"
                fullscreen={false}
            >
                <Modal.Body>
                    <div style={{...styles.borderHr, ...styles.frameTitlePopup}}>
                        <div style={{...styles.icon, ...styles.mr10}}/>
                        <div style={styles.titleH1Popup}>
                            Supprimer l'attribution
                        </div>
                        <a href={'#'} onClick={() => setDeleteLocations(false)}>
                            <img src={"/assets/icons/close.png"} alt="icon-close"
                                 style={{...styles.icon, ...styles.mr10}}/>
                        </a>
                    </div>
                    <div style={styles.contentModal}>
                        <div style={styles.contentLabel}>
                            <label htmlFor="label" style={styles.label}>Supprimer l'attribution d'une location à un
                                concierge</label>
                        </div>

                        <div style={styles.contentBtn}>
                            <div className={'row-table mt-40'}>
                                <a style={styles.btnKReverse} href={'#'} onClick={() => removeProperty()}> Supprimer</a>
                            </div>
                        </div>


                    </div>

                </Modal.Body>
            </Modal>

            <Modal
                show={photoLocation}
                onHide={() => setPhotoLocation(false)}
                contentClassName="modal-little-custom"
                fullscreen={false}
            >
                <Modal.Body>
                    <div style={{...styles.borderHr, ...styles.frameTitlePopup}}>
                        <div style={{...styles.icon, ...styles.mr10}}/>
                        <div style={styles.titleH1Popup}>
                            Modifier la photo
                        </div>
                        <a href={'#'} onClick={() => setPhotoLocation(false)}>
                            <img src={"/assets/icons/close.png"} alt="icon-close"
                                 style={{...styles.icon, ...styles.mr10}}/>
                        </a>
                    </div>
                    <div style={styles.contentModal}>
                        <div style={styles.contentLabel}>
                            <label htmlFor="label" style={styles.label}>Mettre une nouvelle photo</label>
                            <input type="file" className="form-control" name="image" onChange={onFileChange}/>
                        </div>

                        <div style={styles.contentBtn}>
                            <div className={'row-table mt-40'}>
                                <a style={styles.btnKReverse} href={'#'} onClick={() => photoProperty()}> Envoyer</a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    );
}

export default Radium(Properties);
