import styles from "../styles/locations";
import React, {useEffect, useState} from 'react';
import Navbar from "../components/navbar";
import Radium from "radium";
import {useQuery, useQueryClient} from "react-query";
import {getMe} from "../api/users";
import {useNavigate, useParams} from "react-router-dom";
import {getInOuts} from "../api/reservations";
import {dateEnToFr2} from "../utils/utils";
import Header from "../components/header";

function InOuts() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const times = ['day', 'week'];
    const { time: t } = useParams();
    const [time, setTime] = useState(t??'day');

    const {data: inouts} = useQuery(['inouts', time], () => getInOuts({time}));

    const {data: me} = useQuery('me', () => getMe(navigate));

    useEffect(() => {
        if (!times.includes(t)) {
            navigate(`/in-outs/day`);
        }
    }, []);

    const changeTime= async (t) => {
        await setTime(t);
        await queryClient.invalidateQueries(['inouts', t])
        window.history.replaceState(null, "Dashboard KyrnovaRent", "/in-outs/"+t)
    }

    return (
        <div style={styles.page}>
            <Header />

            <Navbar page={'in-outs'} me={me} />
            <div style={styles.containerFluid}>
                <div style={styles.contentCard}>
                    {times?.map(t => (
                        <div style={styles.times}>
                            <a onClick={() => changeTime(t)}>{t === 'day' && 'Jour'} {t === 'week' && 'Semaine'}</a>
                        </div>
                    ))}
                </div>
            </div>
            <div style={styles.SectionInfo}>
                <div style={styles.h1}>
                    Liste des <span style={styles.h1W}>entrées</span> {time === 'day' && 'du jour'} {time === 'week' && 'de la semaine'}
                </div>
            </div>

            <div style={styles.containerFluid}>

                <div style={styles.contentCard}>
                    {!inouts && <div style={styles.shadow}>
                        <div style={styles.Card}>
                            <div style={styles.Card__title}>
                                <div style={styles.Card__title__name}>
                                    Chargement en cours
                                </div>
                            </div>
                        </div>
                    </div>}
                    {inouts?.ins?.length === 0 && 'Aucune entrée'}

                    {inouts?.ins?.map((inout, i, array) => {
                        return (
                            <a href={`/reservations/${inout.id}`} style={styles.shadow}>
                                <div style={styles.Card}>
                                    <div style={styles.Card__title}>
                                        <div style={{...styles.Card__title__name, ...styles.Card__title__name__double}}>
                                            <div>{inout?.property_object?.name??'Inconnu'}</div>
                                            <div>{dateEnToFr2(inout?.arrival_date)}</div>
                                        </div>
                                        <div style={styles.Card__subtitle__name}>
                                            {inout?.client_name}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        )
                    })}
                </div>


            </div>
            <div style={styles.SectionInfo}>
                <div style={styles.h1}>
                    Liste des <span style={styles.h1W}>sorties</span> {time === 'day' && 'du jour'} {time === 'week' && 'de la semaine'}
                </div>
            </div>

            <div style={styles.containerFluid}>
                <div style={styles.contentCard}>
                    {!inouts && <div style={styles.shadow}>
                        <div style={styles.Card}>
                            <div style={styles.Card__title}>
                                <div style={styles.Card__title__name}>
                                    Chargement en cours
                                </div>
                            </div>
                        </div>
                    </div>}
                    {inouts?.outs?.length === 0 && 'Aucune sortie'}

                    {inouts?.outs?.map((inout, i, array) => {
                        return (
                            <a href={`/reservations/${inout.id}`} style={styles.shadow}>
                                <div style={styles.Card}>
                                    <div style={styles.Card__title}>
                                        <div style={styles.Card__title__name}>
                                            <div>{inout?.property_object?.name??'Inconnu'}</div>
                                            <div>{dateEnToFr2(inout?.departure_date)}</div>
                                        </div>
                                        <div style={styles.Card__subtitle__name}>
                                            {inout?.client_name}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        )
                    })}
                </div>


            </div>
        </div>
    );
}

export default Radium(InOuts);
