import {asyncLocalStorage} from "./asyncLocalStorage";

let emailCache = null;
let passwordCache = null;
let tokenCache = null;

async function setEmail(email = null) {
  try {
    // ---- Dans le storage ----
    if (email !== null) {
      await asyncLocalStorage.setItem('email', JSON.stringify(email));
    } else {
      await asyncLocalStorage.removeItem('email');
    }

    // ---- Et en local ----
    emailCache = email;
  } catch (error) {
    console.error(error);
  }
}

async function getEmail() {
  // ---- Si il n'y a rien en local ----
  if (emailCache === null) {
    try {
      // ---- On regarde le storage ----
      const value = await asyncLocalStorage.getItem('email');
      if (value !== null) {
        emailCache = JSON.parse(value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (emailCache === null || typeof emailCache === 'object') {
    return null;
  }

  return emailCache;
}

async function setPassword(password = null) {
  try {
    // ---- Dans le storage ----
    if (password !== null) {
      await asyncLocalStorage.setItem('password', JSON.stringify(password));
    } else {
      await asyncLocalStorage.removeItem('password');
    }

    // ---- Et en local ----
    passwordCache = password;
  } catch (error) {
    console.error(error);
  }
}

async function getPassword() {
  // ---- Si il n'y a rien en local ----
  if (passwordCache === null) {
    try {
      // ---- On regarde le storage ----
      const value = await asyncLocalStorage.getItem('password');
      if (value !== null) {
        passwordCache = JSON.parse(value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (passwordCache === null || typeof passwordCache === 'object') {
    return null;
  }

  return passwordCache;
}

async function setToken(token = null) {
  try {
    // ---- Dans le storage ----
    if (token !== null) {
      await asyncLocalStorage.setItem('token', JSON.stringify(token));
    } else {
      await asyncLocalStorage.removeItem('token');
    }

    // ---- Et en local ----
    tokenCache = token;
  } catch (error) {
    console.error(error);
  }
}

async function getToken() {
  // ---- Si il n'y a rien en local ----
  if (tokenCache === null) {
    try {
      // ---- On regarde le storage ----
      const value = await asyncLocalStorage.getItem('token');
      if (value !== null) {
        tokenCache = JSON.parse(value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (tokenCache === null || typeof tokenCache === 'object') {
    return null;
  }

  return tokenCache;
}

export default {
  setEmail,
  getEmail,
  setPassword,
  getPassword,
  setToken,
  getToken,
};
