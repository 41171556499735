import axios from 'axios';
import {asyncLocalStorage} from "../utils/asyncLocalStorage";

let axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async request => {
    const token = JSON.parse(await asyncLocalStorage.getItem('token'));
      //alert("Bearer " + token)
    if (token) {
      request.headers.Authorization =  token;
    }
    request.timeout = 0;
    return request;
  },
  error => {
    Promise.reject(error);
  },
);

export default axiosInstance;
