import axiosInstance from "../interceptor/tokenInterceptor";
import {rootApi} from "./config";

export const getInOuts = async ({time=null}) => {
    const response = await axiosInstance.get(rootApi + '/inouts?time='+time);
    return response.data;
};

export const getReservations = async ({year=null, logement_id=null}) => {
    let request = '' ;
    if (year) {
        request += '?year='+year;
    }
    if (logement_id) {
        request += request ? '&logement_id='+logement_id : '?logement_id='+logement_id;
    }
    const response = await axiosInstance.get(rootApi + '/reservations'+request);
    return response.data;
};

export const getReservation = async (id) => {
    const response = await axiosInstance.get(rootApi + '/reservations/'+id);
    console.log(response.data);
    return response.data;
};
